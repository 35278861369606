import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MatSliderModule } from "@angular/material/slider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatBadgeModule } from "@angular/material/badge";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app.routing.module";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
//

import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SliderComponent } from "./components/slider/slider.component";
import { OffersComponent } from "./components/offers/offers.component";
import { SeleccionadorComponent } from "./components/seleccionador/seleccionador.component";
import { Error404pageComponent } from "./components/error404page/error404page.component";
import { HomeComponent } from "./components/home/home.component";
import { SocialproofComponent } from "./components/socialproof/socialproof.component";
import { AboutusComponent } from "./components/aboutus/aboutus.component";
import { TariffCardComponent } from "./components/tariff-card/tariff-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CartComponent } from "./components/cart/cart.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { EmpresaComponent } from "./components/empresa/empresa.component";
import { SupportCardComponent } from "./components/empresa/support-card/support-card.component";
import { CloudpbxCardComponent } from "./components/empresa/cloudpbx-card/cloudpbx-card.component";
import { AppmobileComponent } from "./components/appmobile/appmobile.component";
import { RRSSComponent } from "./components/rrss/rrss.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { APIService } from "./services/api.service";
import { OurServicesComponent } from "./components/our-services/our-services.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PartnersComponent } from "./components/partners/partners.component";
import { ClientVoiceComponent } from "./components/client-voice/client-voice.component";
import { VoiceCardComponent } from "./components/client-voice/voice-card/voice-card.component";
import { GeneralConditionsComponent } from "./components/general-conditions/general-conditions.component";
import { ContactInfoComponent } from "./components/contact-info/contact-info.component";
import { AvisolegalComponent } from "./components/avisolegal/avisolegal.component";
import { LegalPrivacidadComponent } from "./components/legal-privacidad/legal-privacidad.component";
import { CoberturaComponent } from "./components/cobertura/cobertura.component";
import { CheckNetDialogComponent } from "./components/cobertura/check-net-dialog/check-net-dialog.component";
import { InfoempresasComponent } from "./components/infoempresas/infoempresas.component";
import {
  CookieBannerComponent,
  CookiesConfigComponent,
} from "./components/cookies/cookie-banner/cookie-banner.component";
import { CookieService } from "ngx-cookie-service";
import { InfointernetComponent } from "./components/infoempresas/infointernet/infointernet.component";
import { SorteosComponent } from "./components/sorteos/sorteos.component";
// @NgModule()

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    OffersComponent,
    SeleccionadorComponent,
    Error404pageComponent,
    HomeComponent,
    SocialproofComponent,
    AboutusComponent,
    TariffCardComponent,
    CartComponent,
    ContactUsComponent,
    EmpresaComponent,
    SupportCardComponent,
    CloudpbxCardComponent,
    AppmobileComponent,
    RRSSComponent,
    WelcomeComponent,
    PartnersComponent,
    ClientVoiceComponent,
    VoiceCardComponent,
    OurServicesComponent,
    GeneralConditionsComponent,
    ContactInfoComponent,
    AvisolegalComponent,
    LegalPrivacidadComponent,
    CoberturaComponent,
    CheckNetDialogComponent,
    InfoempresasComponent,
    CookiesConfigComponent,
    CookieBannerComponent,
    InfointernetComponent,
    SorteosComponent,
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    DragDropModule,
    MatBadgeModule,
    MatGridListModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
  ],
  exports: [HeaderComponent, CookiesConfigComponent],
  providers: [HttpClient, APIService, MatSnackBar, CookieService],
  bootstrap: [AppComponent],
  entryComponents: [CheckNetDialogComponent],
})
export class AppModule {}
