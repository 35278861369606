import { Pack, PackType } from "../models/pack.interface";

const movil: Pack[] = [
  {
    name: "SENSACIONAL",
    price: 24.7,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "100GB",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA BENJAMIN",
    price: 5.7,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "2GB",
      fiberSpeed: 0,
      extra: ["150 minutos"],
    },
  },
  {
    name: "TARIFA ALEVIN",
    price: 6.52,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "100MB DE DATOS",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA INFANTIL",
    price: 8.18,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "10GB + 2Gb",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "INFANTIL +",
    price: 9,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "14GB DE DATOS",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "TARIFA CADETE",
    price: 12.3,
    type: PackType.MOVIL,
    items: {
      lines: 1,
      landline: false,
      mobileData: "25Gb +25Gb +10Gb",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
];

const movilHATTRICK: Pack[] = [
  {
    name: "HATTRICK 40",
    price: 15.7,
    type: PackType.MOVIL,
    items: {
      lines: 3,
      landline: false,
      mobileData: "40GB A COMPARTIR",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "HATTRICK 60",
    price: 19.7,
    type: PackType.MOVIL,
    items: {
      lines: 3,
      landline: false,
      mobileData: "60GB A COMPARTIR",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "HATTRICK 120",
    price: 27.6,
    type: PackType.MOVIL,
    items: {
      lines: 3,
      landline: false,
      mobileData: "120GB A COMPARTIR",
      fiberSpeed: 0,
      extra: ["Llamadas ilimitadas"],
    },
  },
];

const convergente: Pack[] = [
  {
    name: "PACK AMATEUR",
    price: 15.24,
    type: PackType.PACK,
    items: {
      lines: 1,
      landline: true,
      mobileData: "5GB",
      fiberSpeed: 300,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK AMATEUR PLUS",
    price: 14.42,
    type: PackType.PACK,
    items: {
      lines: 1,
      landline: true,
      mobileData: "10Gb + 10Gb. OFERTA hasta 30/04",
      fiberSpeed: 300,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK GOL",
    price: 16.5,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "40GB A COMPARTIR. OFERTA hasta 30/04",
      fiberSpeed: 300,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK MARCADOR",
    price: 18.7,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "100GB A COMPARTIR",
      fiberSpeed: 300,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK TRIBUNA",
    price: 20.62,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "40GB A COMPARTIR. OFERTA hasta 30/04",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK SIAREIRO",
    price: 18.55,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "20GB A COMPARTIR. OFERTA hasta 30/04",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK FAMILIA PLUS",
    price: 26.81,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "80GB A COMPARTIR",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK LIGA",
    price: 30.9,
    type: PackType.PACK,
    items: {
      lines: 4,
      landline: true,
      mobileData: "140GB A COMPARTIR",
      fiberSpeed: 300,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK LIGA PLUS",
    price: 33,
    type: PackType.PACK,
    items: {
      lines: 4,
      landline: true,
      mobileData: "140GB A COMPARTIR",
      fiberSpeed: 600,
      extra: ["Llamadas ilimitadas"],
    },
  },
  {
    name: "PACK CELESTE",
    price: 22.7,
    type: PackType.PACK,
    items: {
      lines: 3,
      landline: true,
      mobileData: "40GB A COMPARTIR. OFERTA hasta 30/04",
      fiberSpeed: 1000,
      extra: ["Llamadas ilimitadas"],
    },
  },
];

const fibra: Pack[] = [
  {
    name: "FIBRA BASE",
    price: 28.85,
    type: PackType.FIBRA,
    items: {
      lines: 0,
      landline: false,
      mobileData: "",
      fiberSpeed: 300,
    },
  },
  {
    name: "FIBRA LEYENDA",
    price: 41.2,
    type: PackType.FIBRA,
    items: {
      lines: 0,
      landline: false,
      mobileData: "",
      fiberSpeed: 600,
    },
  },
  {
    name: "TARIFA CANTERANO",
    price: 41.2,
    type: PackType.FIBRA,
    items: {
      lines: 0,
      landline: false,
      mobileData: "",
      fiberSpeed: 0,
      extra: ["ROUTER 4G con DATOS ILIMITADOS"],
    },
  },
];

export const packs: Pack[] = [
  ...movil,
  ...movilHATTRICK,
  ...convergente,
  ...fibra,
];
