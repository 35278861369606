<section>
  <div class="container-fluid check-network">
    <div class="row left-side">
      <img
        class="img-iago"
        src="../../../assets/IMG/Highlights/celta_mobile_iago2.jpg"
        alt=""
      />
    </div>
    <div class="right-side">
      <h1 class="title">CONSULTA TU COBERTURA</h1>
      <h3 class="subtitle">
        Primero hay que saber si podemos darte servicio en tu dirección
      </h3>
      <mat-card class="address-form">
        <form [formGroup]="postCodeForm" class="tile-container">
          <h4>¿Cual es tu código Postal?</h4>
          <mat-form-field class="full-width-control">
            <mat-label>Código Postal</mat-label>
            <input matInput formControlName="post_code" />
            <mat-error *ngIf="f_1.post_code.hasError('required')"
              >Debes ingresar un código postal para continuar</mat-error
            >
          </mat-form-field>
          <div *ngIf="showError" class="error-message">
            <h5>El código postal debe tener 5 numeros</h5>
          </div>
          <div *ngIf="!existsInDb" class="error-message">
            <h5>El código postal no se encuentra en nuestra base de datos</h5>
          </div>
        </form>
        <div *ngIf="loading">
          <mat-progress-bar color="primary" mode="indeterminate">
          </mat-progress-bar>
          <hr />
        </div>
      </mat-card>

      <mat-card *ngIf="second_stage" class="address-form">
        <form
          [formGroup]="addressForm"
          (ngSubmit)="submitAddress()"
          class="tile-container"
        >
          <h1>Vives en {{ town }}, provincia de {{ province }}</h1>
          <h4>¿Cual es el nombre de la calle?</h4>
          <mat-form-field appearance="fill">
            <mat-label>¿Es la calle alguna de estas?</mat-label>
            <input
              type="text"
              placeholder="Ingresa tu dirección"
              matInput
              formControlName="street_name"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option.street_type + ' ' + option.street_name"
              >
                {{ option.street_type }} {{ option.street_name }}
              </mat-option>
            </mat-autocomplete>
            <!-- <mat-select formControlName="street_name">
                            <mat-option *ngFor='let street of addresses_from_post_code' value="{{street.street_name}}">
                                {{street.street_type}} {{street.street_name}}
                            </mat-option>
                        </mat-select> -->
          </mat-form-field>
          <h4>¿Cual es tu número de portal?</h4>
          <mat-form-field class="full-width-control">
            <mat-label>Número de portal</mat-label>
            <input matInput formControlName="number" type="number" min="0" />
            <mat-error *ngIf="f_2.number.hasError('required')"
              >Debes ingresar un número para continuar</mat-error
            >
          </mat-form-field>
          <button
            [disabled]="f_2.street_name.hasError('required')"
            mat-raised-button
            color="accent"
            type="submit"
            class="contact-button"
          >
            Comprobar
          </button>
        </form>
        <button
          mat-button
          color="accent"
          type="submit"
          class="contact-button"
          (click)="streetNotFound()"
        >
          Mi calle no está en la lista
        </button>
      </mat-card>
    </div>
  </div>
</section>
