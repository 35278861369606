import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ResponsePostCode } from "src/app/models/address.models";
import { ContactEmail } from "src/app/models/contact.interface";
import { APIService } from "src/app/services/api.service";

@Component({
  selector: "app-check-net-dialog",
  templateUrl: "./check-net-dialog.component.html",
  styleUrls: ["./check-net-dialog.component.scss"],
})
export class CheckNetDialogComponent implements OnInit {
  response: ResponsePostCode[] = [];
  model: ResponsePostCode;
  emailForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api_service: APIService,
    public dialogRef: MatDialogRef<CheckNetDialogComponent>,
    private _fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.response = this.data;
    this.model = this.data[0];
    this.emailForm = this._fb.group({
      name: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
    });
  }

  sendEmail() {
    let content = !this.api_service.saved_street_name
      ? `<h4>El cliente no ha encontrado su calle y le gustaría ponerse en contato</h4> <br>${
          this.api_service.saved_post_code +
          ". " +
          this.api_service.saved_town +
          ". (" +
          this.api_service.saved_province +
          ")"
        }`
      : "<h4>El cliente nos pregunta si cuenta con cobertura de fibra para la direccion facilitada: </h4>" +
          this.api_service.saved_street_name ||
        "" + " " + this.api_service.saved_street_number ||
        "" +
          ". " +
          this.api_service.saved_post_code +
          ". " +
          this.api_service.saved_town +
          ". (" +
          this.api_service.saved_province +
          ")" +
          "</h4>";

    let to: string = "contacto@nexotelecom.es";
    let subject: string =
      this.emailForm.controls.name.value +
      " consulta cobertura a través de CELTAMOBILE ";
    let text: string =
      this.emailForm.controls.name.value +
      " consulta cobertura a través de CELTAMOBILE " +
      this.api_service.saved_street_name +
      " " +
      this.api_service.saved_street_number +
      ". " +
      this.api_service.saved_post_code +
      ". " +
      this.api_service.saved_town +
      ". (" +
      this.api_service.saved_province +
      ")";
    let html: string =
      "<h2>Nombre del cliente: <strong>" +
      this.emailForm.controls.name.value +
      "</strong></h2><h4>Correo electrónico: " +
      this.emailForm.controls.email.value +
      "</h4><h4>Número de teléfono: " +
      this.emailForm.controls.phone.value +
      "</h4>" +
      content;
    let email_body: ContactEmail = {
      to: to,
      subject: subject,
      text: text,
      html: html,
    };

    this.api_service.sendEmail(email_body).subscribe(
      (res) => {
        let success: string = res.split(":")[0];
        this._snackBar.open(success, "Aceptar", {
          duration: 2000,
        });
        this.emailForm.reset();
      },
      (err) => {
        console.log(err.error);
      }
    );
  }

  get f() {
    return this.emailForm.controls;
  }
}
