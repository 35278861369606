<section>
  <div class="welcome2">
    <h2 data-aos="fade-down" data-aos-duration="1500">
      <strong style="color: #6cace4">CELTA MOBILE </strong> LA OPERADORA QUE
      TOCA A FIBRA
    </h2>
    <div class="divider" data-aos="fade-right" data-aos-duration="1500"></div>

    <p>
      Te ofrecemos las mejores tarifas para que lleves siempre contigo el
      sentimiento celeste.
      <br />
      Escoge el producto que mejor se adapte a tus necesidades, desde la tarifa
      Benjamín hasta el Pack Família Plus.
      <br />
      Disfruta de la mejor cobertura y sin ataduras.
    </p>
  </div>

  <!--
    <div class="slide">
        <div class="subtitle color-blue">EMPRESAS</div>
        <mat-slide-toggle class="mat-slide" aria-label="Welcome" [(ngModel)]="checked" (change)="slideChange()"></mat-slide-toggle>
        <div class="subtitle color-pink">PARTICULARES</div>
    </div> -->
</section>
