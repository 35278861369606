import { Component, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Pack, PackType } from 'src/app/models/pack.interface';
import { CartService } from 'src/app/services/cart.service';
import { SelectorService } from 'src/app/services/selector.service';

@Component({
  selector: 'app-seleccionador',
  templateUrl: './seleccionador.component.html',
  styleUrls: ['./seleccionador.component.scss'],
})
export class SeleccionadorComponent implements OnInit {
  linesNumber = 0;
  fiberSlider = 0;
  fiber = 0;
  fiberSpeed = [0, 50, 100, 300, 600, 1000];
  mainFilter = '';
  packs: Pack[] = [];
  filteredPacks: Pack[] = [];
  displayedSpeed = '';

  constructor(
    private selectorService: SelectorService,
    private cartService: CartService
  ) {}
  ngOnInit(): void {
    this.getPacks();
/*     this.packs = this.selectorService.getAllPacks(); */
    this.filteredPacks = this.packs;
    this.cartService.openCart();
  }
  //Function returns last value of an array that contains a name as a key
  returnObject(object: any, name: string) {
    let result:string | number | any | null = null;
    object.forEach((element) => {
      if (element.name === name) {
        if(name === 'lineas'){
          result = parseInt(element?.options.at(-1))
        }else if(name=== 'velocidad'){
          result = parseInt(element?.options.at(-1).slice(0, -2));
        }/* else if(name === 'datosmoviles'){
          result = element?.options.at(-1)
        } */else{
          result = element?.options.at(-1);
        }
      }
    });
    return result;
  }

  returnType(object){
    let result;
    if(object.slug === 'internet'){
      result = 'fibra'
    }else if(object.slug === 'movil'){
      result = 'movil'
    }else if(object.slug === 'pack'){
      result = 'pack'
    }else{
      result = null;
    }
    return result
  }

  
  getPacks() {
    this.selectorService
      .getPackAll("&per_page=100")
      .pipe(
        map((response: any) => {
          let array: any = [];

          const priority = {
            pack: 1,
            internet: 2,
            movil: 3,
         };
        
           response = response
           .filter( respuesta => respuesta.tags[0].slug !== 'empresa')
           .filter( respuesta =>  respuesta.tags[1]?.slug !== 'empresa')
        
           .sort((a, b) =>  a.price - b.price)
           .sort((a, b) => priority[b.categories[0].slug] - priority[a.categories[0].slug]);


          for (const key in response) {
  
            array.push({
              name: response[key].name,
              price: parseFloat(response[key].price),
              type: (this.returnType(response[key].categories[0])) === null ? 0 : this.returnType(response[key].categories[0]) ,
              items: {
                lines: (this.returnObject(response[key].attributes, "lineas")),
                landline: this.returnObject(response[key].attributes, "fijo"),
                mobileData: this.returnObject(
                  response[key].attributes,
                  "datosmoviles"
                ),
                fiberSpeed: this.returnObject(
                  response[key].attributes,
                  "velocidad"
                ),
                extra: this.returnObject(response[key].attributes, "extra") ?  [this.returnObject(response[key].attributes, "extra")] : []
              },
            });
          }
          return array;
        }),
        tap((packs: Pack[]) => {
          this.packs = packs;
          this.filteredPacks = packs;
        })
      )
      .subscribe();
  }

  addLine() {
    this.linesNumber++;
    this.filterPacks();
  }
  deleteLine() {
    if (this.linesNumber === 0) {
      this.filterPacks();
      return;
    }
    this.linesNumber--;
    this.filterPacks();
  }

  changeSlider(a: any) {
    this.fiber = this.fiberSpeed[a.value];
    this.displayedSpeed = this.fiber.toString();
    this.filterPacks();
  }
  filterPacks() {
    
    switch (this.mainFilter) {
      case "":
        this.filteredPacks = this.packs;
  /*       this.filteredPacks = this.selectorService.getPacks(); */
        this.filterPackByLines();
        this.filterPackByFiber();
        break;
      case PackType.MOVIL:
        this.filteredPacks = this.packs.filter(
          (p) => p.type === PackType.MOVIL
        );
        this.filterPackByLines();
        break;
      case PackType.FIBRA:
        this.filteredPacks = this.packs.filter(
          (p) => p.type === PackType.FIBRA
        );
        this.filterPackByFiber();
        break;
      case PackType.PACK:
        this.filteredPacks = this.packs.filter((p) => p.type === PackType.PACK);
        this.filterPackByLines();
        this.filterPackByFiber();
        break;
    }
  }

  filterPackByLines() {
    if (this.linesNumber === 0) return;
    this.filteredPacks = this.filteredPacks.filter(
      (p) => p.items.lines >= this.linesNumber
    );
  }
  filterPackByFiber() {
    if (this.fiber === 0) return;
    this.filteredPacks = this.filteredPacks.filter(
      (p) => p.items.fiberSpeed >= this.fiber
    );
  }

  getConvertedMB = (mb, presition = 0) => {
    const unit = mb < 1000 ? "MB" : "GB";
    if (mb < 1000) {
      return `${mb} ${unit}`;
    }
    const gb = mb / 1000;
    return `${gb.toFixed(presition)} ${unit}`;
  };
}
