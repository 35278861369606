<section>
    <div class="container-fluid contact-us">
        <div class='row left-side'>
            <img src="../../../assets/IMG/Highlights/celta_mobile_equipo.jpg" alt="">
        </div>
        <div class='right-side'>
            <mat-card style="max-width: 450px;">
                <form [formGroup]="contactForm" (ngSubmit)="submit()" class='tile-container'>
                    <h1>¿En qué podemos ayudarte?</h1>

                    <mat-form-field class="full-width-control">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name">
                        <mat-error *ngIf="f.name.hasError('required')">Debe ingresar un nombre</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width-control">
                        <mat-label>Número de Teléfono</mat-label>
                        <input matInput formControlName="phone_number">
                        <mat-error *ngIf="f.phone_number.hasError('required')">Debe ingresar un Teléfono</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width-control">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-error *ngIf="f.email.invalid">Debe ingresar un email en formato válido</mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width-control">
                        <mat-label>Comentario</mat-label>
                        <textarea matInput class="text-area" maxlength="1000" formControlName='text_input'></textarea>
                        <mat-error *ngIf="f.text_input.hasError('maxlength')">Máximo 1000 characters</mat-error>
                    </mat-form-field>

                    <mat-checkbox required class="checkbox" type="checkbox" formControlName="acceptlegal">
                        <span (click)="openDialog()">Acepto la Política de Privacidad</span>

                    </mat-checkbox>

                    <button mat-raised-button color="accent" type="submit" class='contact-button'>Enviar</button>
                </form>
            </mat-card>
        </div>

    </div>
</section>