<footer>
  <div class="branding">
    <div class="info">
      <p>
        <a [href]="'/condiciones-contratacion'">Condiciones de Contratación</a>
      </p>
      <p><a [href]="'/aviso-legal'">Aviso legal</a></p>
      <p><a [href]="'/politica-de-privacidad'">Política de privacidad </a></p>
      <p>&nbsp;&nbsp;© 2022 Celta Mobile</p>
    </div>
  </div>
  <app-rrss></app-rrss>
</footer>
