import { Component, Input, OnInit } from "@angular/core";
import { Pack } from "src/app/models/pack.interface";
import { SelectorService } from "src/app/services/selector.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-tariff-card",
  templateUrl: "./tariff-card.component.html",
  styleUrls: ["./tariff-card.component.scss"],
})
export class TariffCardComponent implements OnInit {
  @Input() packData!: Pack;
  dividerSize = 0;
  showInfo = false;
  constructor(
    private selectorService: SelectorService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  addToCart() {
    this.selectorService.addToCart(this.packData);
    this._snackBar.open("Pack añadido al carrito!", "OK", {
      duration: 2000,
    });
  }

  getInteger(num: number) {
    return Math.floor(num);
  }
  getDecimal(num: number) {
    return num.toFixed(2).split(".")[1];
  }
}
