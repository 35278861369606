import { Component, OnInit } from "@angular/core";
import { CloudPBX, Support } from "src/app/models/corporate.interface";
import { cloud_pbx } from "src/app/data/corporateData";
import { ActivatedRoute } from "@angular/router";
import { map, tap } from "rxjs/operators";
import { SelectorService } from "src/app/services/selector.service";

@Component({
  selector: "app-empresa",
  templateUrl: "./empresa.component.html",
  styleUrls: ["./empresa.component.scss"],
})
export class EmpresaComponent implements OnInit {
  // support: Support[]
  cloud_pbx: CloudPBX[];
  empresasRouter: boolean = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private selectorService: SelectorService
    ) {}

  ngOnInit(): void {
    const array = this.activatedRoute.snapshot.url;
    if (array.length > 0)
      this.empresasRouter = array[0].path === 'empresas' ? true : false;
 /*    this.cloud_pbx = cloud_pbx; */
    this.getPacks()
  }

 //Function returns last value of an array that contains a name as a key
 returnObject(object: any, name: string) {
  let result:string | number | any | null = null;
  object.forEach((element) => {
    if (element.name === name) {
      if(name === 'ddi' || name==='minutes_landline'|| name==='minutes_mobile'|| name==='concurrent_calls' || name==='fax'){
        result = parseInt(element?.options.at(-1));
      }else if(name=== 'switchboard' || name === 'control_panel'){
        result = element?.options.at(-1)
      }
    }
  });
  return result;
}



getPacks() {
  this.selectorService
    .getPackAll("&per_page=100")
    .pipe(
      map((response: any) => {
        let array: any = [];
        for (const key in response) {
          if ( 
            response[key].tags[1]?.slug == "empresa" ||
            response[key].tags[0]?.slug == "empresa"
           ){
          array.push({
            name: response[key].name,
            price: parseFloat(response[key].price),
            ddi: this.returnObject(response[key].attributes, "ddi"),
            minutes_landline: this.returnObject(response[key].attributes, "minutes_landline"),
            minutes_mobile: this.returnObject(response[key].attributes, "minutes_mobile"),
            concurrent_calls: this.returnObject(response[key].attributes, "concurrent_calls"),
            fax: this.returnObject(response[key].attributes, "fax"),
            switchboard: this.returnObject(response[key].attributes, "switchboard"),
            control_panel: this.returnObject(response[key].attributes, "control_panel"),
          });
          }
        }
        return array;
      }),
      tap((packs: CloudPBX[]) => {
        this.cloud_pbx = packs;
      })
    )
    .subscribe();
}


}
