<section>
  <article class="politica-privacidad">
    <div class="entry-content">
      <p>&nbsp;</p>
      <h1 style="text-align: center; line-height: 80px">
        Política de privacidad
      </h1>

      <p>
        En NEXO GLOBAL MANAGEMENTS, S.L. nos preocupamos por la privacidad y la
        transparencia.<br />
        A continuación, le indicamos en detalle los tratamientos de datos
        personales que realizamos, así como toda la información relativa a los
        mismos.<br />
        <strong
          >Tratamiento de imágenes de clientes o potenciales clientes con fines
          promocionales</strong
        >
      </p>

      <p>
        <strong>Responsable:</strong> NEXO GLOBAL MANAGEMENT SL<br />
        <strong> Finalidad:</strong> Captación y difusión de imagen y/o vídeo
        con fines promocionales.<br />
        <strong>Legitimación:</strong> Consentimiento del interesado. <br />
        <strong>Destinatarios:</strong>Están previstas cesiones de datos a:
        Internet (redes sociales, plataformas de video y sitios web), página web
        de la entidad y otros medios de comunicación. Están previstas
        transferencias a terceros países.<br />
        <strong>Derechos:</strong> Tiene derecho a acceder, rectificar y
        suprimir los datos, así como otros derechos, indicados en la información
        adicional, que puede ejercer dirigiéndose a contacto@nexo.es o Calle
        Carboner 40, Parque Táctica 46988 Paterna (Valencia).<br />
        <strong>Procedencia:</strong> El propio interesado.
      </p>

      <h2 style="text-align: center; line-height: 80px">
        Información completa sobre la Protección de datos
      </h2>
      <h3>1. ¿Quién es el responsable del tratamiento de sus datos?</h3>
      <p>
        NEXO GLOBAL MANAGEMENTS, S.L. <br />
        B97341499.<br />
        Calle Carboner 40, Parque Táctica 46988 Paterna (Valencia).<br />
        676222777.<br />
        contacto@nexo.es
      </p>

      <h3>2. ¿Con qué finalidad tratamos sus datos personales?</h3>
      <p>
        En NEXO GLOBAL MANAGEMENTS, S.L. tratamos la información que nos
        facilitan las personas interesadas con el fin de realizar la captación,
        difusión y cesión de imagen y/o vídeo de clientes y potenciales clientes
        con fines promocionales para su publicación en Internet (redes sociales,
        plataformas de video y sitios web), en las publicaciones de la entidad y
        otros medios de comunicación. En el caso de que no facilite sus datos
        personales, no podremos cumplir con las funcionalidades descritas
        anteriormente. <br />No se van a tomar decisiones automatizadas en base
        a los datos proporcionados.
      </p>

      <h3>3. ¿Por cuánto tiempo conservaremos sus datos?</h3>
      <p>
        Los datos se conservarán mientras el interesado no solicite la supresión
        de los mismos.
      </p>

      <h3>4. ¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
      <p>
        Le indicamos la base legal para el tratamiento de sus datos:<br />
        <br />&nbsp; • Consentimiento del interesado: Captación, difusión y
        cesión de imagen y/o vídeo con fines promocionales para su publicación
        en redes sociales, plataformas de vídeo y sitios web, así como en las
        publicaciones de la entidad y otros medios de comunicación. (RGPD art.
        6.1.a).
      </p>

      <h3>5. ¿A qué destinatarios se comunicarán sus datos?</h3>
      <p>
        Los datos se comunicarán a los siguientes destinatarios:<br />
        <br />&nbsp; • Internet (redes sociales, plataformas de video y sitios
        web), página web de la entidad y otros medios de comunicación, con la
        finalidad de cesión y publicación de imágenes y/o vídeo de clientes o
        potenciales clientes con fines promocionales.
      </p>

      <h3>6. Transferencias de datos a terceros países</h3>
      <p>
        Están previstas las siguientes transferencias de datos a terceros
        países:<br />
        <br />&nbsp; • Redes sociales y sistemas de difusión promocional, con la
        finalidad de realizar promoción publicitaria de los servicios de la
        entidad. La garantía para esta transferencia se ha establecido a través
        de: Consentimiento explícito del interesado. Puede consultar información
        adicional en: Política de privacidad de la entidad..
      </p>

      <h3>7. ¿Cuáles son sus derechos cuando nos facilita sus datos?</h3>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si en NEXO
        GLOBAL MANAGEMENTS, S.L. estamos tratando, o no, datos personales que
        les conciernan.<br />
        <br />Las personas interesadas tienen derecho a acceder a sus datos
        personales, así como a solicitar la rectificación de los datos inexactos
        o, en su caso, solicitar su supresión cuando, entre otros motivos, los
        datos ya no sean necesarios para los fines que fueron recogidos.
        Igualmente tiene derecho a la portabilidad de sus datos.<br />
        <br />En determinadas circunstancias, los interesados podrán solicitar
        la limitación del tratamiento de sus datos, en cuyo caso únicamente los
        conservaremos para el ejercicio o la defensa de reclamaciones. <br />
        En determinadas circunstancias y por motivos relacionados con su
        situación particular, los interesados podrán oponerse al tratamiento de
        sus datos. En este caso, NEXO GLOBAL MANAGEMENTS, S.L. dejará de tratar
        los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la
        defensa de posibles reclamaciones.<br />
        <br />
        Podrá ejercitar materialmente sus derechos de la siguiente forma:
        dirigiéndose a contacto@nexo.es o Calle Carboner 40, Parque Táctica
        46988 Paterna (Valencia).<br />
        <br />
        Cuando se realice el envío de comunicaciones comerciales utilizando como
        base jurídica el interés legítimo del responsable, el interesado podrá
        oponerse al tratamiento de sus datos con ese fin.<br />
        <br />
        Si ha otorgado su consentimiento para alguna finalidad concreta, tiene
        derecho a retirar el consentimiento otorgado en cualquier momento, sin
        que ello afecte a la licitud del tratamiento basado en el consentimiento
        previo a su retirada.<br />
        <br />
        En caso de que sienta vulnerados sus derechos en lo concerniente a la
        protección de sus datos personales, especialmente cuando no haya
        obtenido satisfacción en el ejercicio de sus derechos, puede presentar
        una reclamación ante la Autoridad de Control en materia de Protección de
        Datos competente a través de su sitio web: www.aepd.es.<br />
      </p>

      <h3>8. ¿Cómo hemos obtenido sus datos?</h3>
      <p>
        Los datos personales que tratamos en NEXO GLOBAL MANAGEMENTS, S.L.
        proceden de: El propio interesado. Las categorías de datos que se tratan
        son:

        <br />&nbsp; • Datos identificativos.
      </p>

      <h4><strong>Tratamiento de los datos de clientes</strong></h4>
      <p>
        <strong>Responsable:</strong> NEXO GLOBAL MANAGEMENT SL<br />
        <strong> Finalidad:</strong> Prestar los servicios solicitados y enviar
        comunicaciones comerciales.<br />
        <strong>Legitimación:</strong> Ejecución de un contrato.<br />
        Interés legítimo del Responsable. <br />
        <strong>Destinatarios:</strong>Están previstas cesiones de datos a:
        Abogado o despacho de abogados contratado por la empresa; Administración
        Tributaria; Asesoría Contable y Fiscal; Consultora de Protección de
        datos; Empresa de transporte; Entidades financieras.<br />
        <strong>Derechos:</strong> Tiene derecho a acceder, rectificar y
        suprimir los datos, así como otros derechos, indicados en la información
        adicional, que puede ejercer dirigiéndose a contacto@nexo.es o Calle
        Carboner 40, Parque Táctica 46988 Paterna (Valencia).<br />
        <strong>Procedencia:</strong> El propio interesado.
      </p>

      <h2 style="text-align: center; line-height: 80px">
        Información completa sobre la Protección de datos
      </h2>
      <h3>1. ¿Quién es el responsable del tratamiento de sus datos?</h3>
      <p>
        NEXO GLOBAL MANAGEMENTS, S.L. <br />
        B97341499.<br />
        Calle Carboner 40, Parque Táctica 46988 Paterna (Valencia).<br />
        961320025.<br />
        contacto@nexo.es
      </p>

      <h3>2. ¿Con qué finalidad tratamos sus datos personales?</h3>
      <p>
        En NEXO GLOBAL MANAGEMENTS, S.L. tratamos la información que nos
        facilitan las personas interesadas con el fin de realizar la gestión
        administrativa, contable y fiscal de los servicios solicitados, así como
        enviar comunicaciones comerciales sobre nuestros productos y servicios.
        En el caso de que no facilite sus datos personales, no podremos cumplir
        con las funcionalidades descritas anteriormente. <br />No se van a tomar
        decisiones automatizadas en base a los datos proporcionados.
      </p>

      <h3>3. ¿Por cuánto tiempo conservaremos sus datos?</h3>
      <p>
        Los datos se conservarán mientras el interesado no solicite su
        supresión, y en su caso, durante los años necesarios para cumplir con
        las obligaciones legales.
      </p>

      <h3>4. ¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
      <p>
        Le indicamos la base legal para el tratamiento de sus datos:<br />
        <br />&nbsp; • Ejecución de un contrato: Gestión fiscal, contable y
        administrativa de clientes. (RGPD art. 6.1.b).<br />
        <br />&nbsp; • Interés legítimo del Responsable: Envío de comunicaciones
        comerciales incluso por vía electrónica. (RGPD Considerando 47, LSSICE
        art. 21.2).<br />
      </p>

      <h3>5. ¿A qué destinatarios se comunicarán sus datos?</h3>
      <p>
        Los datos se comunicarán a los siguientes destinatarios:<br />
        <br />&nbsp; • Abogado o despacho de abogados contratado por la empresa,
        con la finalidad de gestión de servicios jurídicos.<br />
        <br />&nbsp; • Administración Tributaria, con la finalidad de cumplir
        con las obligaciones legales (requisito legal).<br />
        <br />&nbsp; • Asesoría Contable y Fiscal, con la finalidad de
        confección de la contabilidad e impuestos legalmente establecidos
        (requisito legal).<br />
        <br />&nbsp; • Consultora de Protección de datos, con la finalidad de
        gestión de la protección de datos.<br />
        <br />&nbsp; • Empresa de transporte, con la finalidad de gestión del
        envió de documentación.<br />
        <br />&nbsp; • Entidades financieras, con la finalidad de girar los
        recibos correspondientes (requisito contractual).<br />
      </p>

      <h3>6. Transferencias de datos a terceros países</h3>
      <p>No están previstas transferencias de datos a terceros países.</p>

      <h3>7. ¿Cuáles son sus derechos cuando nos facilita sus datos?</h3>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si en NEXO
        GLOBAL MANAGEMENTS, S.L. estamos tratando, o no, datos personales que
        les conciernan.<br />
        <br />Las personas interesadas tienen derecho a acceder a sus datos
        personales, así como a solicitar la rectificación de los datos inexactos
        o, en su caso, solicitar su supresión cuando, entre otros motivos, los
        datos ya no sean necesarios para los fines que fueron recogidos.
        Igualmente tiene derecho a la portabilidad de sus datos.<br />
        <br />En determinadas circunstancias, los interesados podrán solicitar
        la limitación del tratamiento de sus datos, en cuyo caso únicamente los
        conservaremos para el ejercicio o la defensa de reclamaciones.<br />
        <br />En determinadas circunstancias y por motivos relacionados con su
        situación particular, los interesados podrán oponerse al tratamiento de
        sus datos. En este caso, NEXO GLOBAL MANAGEMENTS, S.L. dejará de tratar
        los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la
        defensa de posibles reclamaciones.<br />
        <br />Podrá ejercitar materialmente sus derechos de la siguiente forma:
        dirigiéndose a contacto@nexo.es o Calle Carboner 40, Parque Táctica
        46988 Paterna (Valencia). <br />Cuando se realice el envío de
        comunicaciones comerciales utilizando como base jurídica el interés
        legítimo del responsable, el interesado podrá oponerse al tratamiento de
        sus datos con ese fin.<br />
        <br />Si ha otorgado su consentimiento para alguna finalidad concreta,
        tiene derecho a retirar el consentimiento otorgado en cualquier momento,
        sin que ello afecte a la licitud del tratamiento basado en el
        consentimiento previo a su retirada.<br />
        <br />En caso de que sienta vulnerados sus derechos en lo concerniente a
        la protección de sus datos personales, especialmente cuando no haya
        obtenido satisfacción en el ejercicio de sus derechos, puede presentar
        una reclamación ante la Autoridad de Control en materia de Protección de
        Datos competente a través de su sitio web: www.aepd.es.<br />
      </p>

      <h3>8. ¿Cómo hemos obtenido sus datos?</h3>
      <p>
        Los datos personales que tratamos en NEXO GLOBAL MANAGEMENTS, S.L.
        proceden de: El propio interesado.
        <br />
        Las categorías de datos que se tratan son: <br />
        <br />&nbsp; • Datos identificativos. <br />
        <br />&nbsp; • Direcciones postales y electrónicas<br />
        <br />&nbsp; • Información comercial<br />
      </p>

      <h4>
        <strong
          >Tratamiento de los datos de potenciales clientes y contactos</strong
        >
      </h4>
      <p>
        <strong>Responsable:</strong> NEXO GLOBAL MANAGEMENT SL<br />
        <strong> Finalidad:</strong> Atender su solicitud y enviarle
        comunicaciones comerciales<br />
        <strong>Legitimación:</strong> Ejecución de un contrato.<br />
        Consentimiento del interesado.<br />
        Interés legítimo del Responsable.<br />
        <strong>Destinatarios:</strong>Están previstas cesiones de datos a:
        Consultora de protección de datos; Proveedores web.<br />
        <strong>Derechos:</strong> Tiene derecho a acceder, rectificar y
        suprimir los datos, así como otros derechos, indicados en la información
        adicional, que puede ejercer dirigiéndose a contacto@nexo.es o Calle
        Carboner 40, Parque Táctica 46988 Paterna (Valencia).<br />
        <strong>Procedencia:</strong> El propio interesado.
      </p>

      <h2 style="text-align: center; line-height: 80px">
        Información completa sobre Protección de datos
      </h2>
      <h3>1. ¿Quién es el responsable del tratamiento de sus datos?</h3>
      <p>
        NEXO GLOBAL MANAGEMENTS, S.L. <br />
        B97341499.<br />
        Calle Carboner 40, Parque Táctica 46988 Paterna (Valencia).<br />
        676222777.<br />
        contacto@nexo.es
      </p>

      <h3>2. ¿Con qué finalidad tratamos sus datos personales?</h3>
      <p>
        En NEXO GLOBAL MANAGEMENTS, S.L. tratamos la información que nos
        facilitan las personas interesadas con el fin de realizar la gestión de
        potenciales clientes que se han interesado sobre nuestros productos y/o
        servicios, así como otros contactos comerciales y realizar, en su caso,
        el envío de comunicaciones comerciales inclusive por vía electrónica. En
        el caso de que no facilite sus datos personales, no podremos cumplir con
        las funcionalidades descritas anteriormente. <br />No se van a tomar
        decisiones automatizadas en base a los datos proporcionados.
      </p>

      <h3>3. ¿Por cuánto tiempo conservaremos sus datos?</h3>
      <p>
        Los datos se conservarán mientras el interesado no solicite su
        supresión.
      </p>

      <h3>4. ¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
      <p>
        Le indicamos la base legal para el tratamiento de sus datos:<br />
        <br />&nbsp; • Ejecución de un contrato: Gestión de potenciales clientes
        que se han interesado sobre nuestros productos y/o servicios. (RGPD,
        art. 6.1.b).<br />
        <br />&nbsp; • Consentimiento del interesado: Enviar comunicaciones
        comerciales, inclusive por vía electrónica. (RGPD, art. 6.1.a, LSSICE
        art.21).<br />
        <br />&nbsp; • Interés legítimo del Responsable: Gestión de los datos de
        contacto profesionales (LOPDGDD art.19, RGPD art. 6.1.f).<br />
      </p>

      <h3>5. ¿A qué destinatarios se comunicarán sus datos?</h3>
      <p>
        Los datos se comunicarán a los siguientes destinatarios:<br />
        <br />&nbsp; • Consultora de protección de datos, con la finalidad de
        gestión de la protección de datos.<br />
        <br />&nbsp; • Proveedores web, con la finalidad de gestionar la página
        web.<br />
      </p>

      <h3>6. Transferencias de datos a terceros países</h3>
      <p>No están previstas transferencias de datos a terceros países.</p>

      <h3>7. ¿Cuáles son sus derechos cuando nos facilita sus datos?</h3>
      <p>
        Cualquier persona tiene derecho a obtener confirmación sobre si en NEXO
        GLOBAL MANAGEMENTS, S.L. estamos tratando, o no, datos personales que
        les conciernan.<br />
        <br />Las personas interesadas tienen derecho a acceder a sus datos
        personales, así como a solicitar la rectificación de los datos inexactos
        o, en su caso, solicitar su supresión cuando, entre otros motivos, los
        datos ya no sean necesarios para los fines que fueron recogidos.
        Igualmente tiene derecho a la portabilidad de sus datos.<br />
        <br />En determinadas circunstancias, los interesados podrán solicitar
        la limitación del tratamiento de sus datos, en cuyo caso únicamente los
        conservaremos para el ejercicio o la defensa de reclamaciones.<br />
        <br />En determinadas circunstancias y por motivos relacionados con su
        situación particular, los interesados podrán oponerse al tratamiento de
        sus datos. En este caso, NEXO GLOBAL MANAGEMENTS, S.L. dejará de tratar
        los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la
        defensa de posibles reclamaciones.<br />
        <br />Podrá ejercitar materialmente sus derechos de la siguiente forma:
        dirigiéndose a contacto@nexo.es o Calle Carboner 40, Parque Táctica
        46988 Paterna (Valencia).<br />
        <br />Cuando se realice el envío de comunicaciones comerciales
        utilizando como base jurídica el interés legítimo del responsable, el
        interesado podrá oponerse al tratamiento de sus datos con ese fin.<br />
        <br />Si ha otorgado su consentimiento para alguna finalidad concreta,
        tiene derecho a retirar el consentimiento otorgado en cualquier momento,
        sin que ello afecte a la licitud del tratamiento basado en el
        consentimiento previo a su retirada.<br />
        <br />En caso de que sienta vulnerados sus derechos en lo concerniente a
        la protección de sus datos personales, especialmente cuando no haya
        obtenido satisfacción en el ejercicio de sus derechos, puede presentar
        una reclamación ante la Autoridad de Control en materia de Protección de
        Datos competente a través de su sitio web: www.aepd.es.<br />
      </p>

      <h3>8. ¿Cómo hemos obtenido sus datos?</h3>
      <p>
        Los datos personales que tratamos en NEXO GLOBAL MANAGEMENTS, S.L.
        proceden de:<br />
        El propio interesado. <br />
        Las categorías de datos que se tratan son: <br />
        <br />&nbsp; • Datos identificativos. <br />
        <br />&nbsp; • Direcciones postales y electrónicas<br />
        <br />&nbsp; • Información comercial<br />
      </p>
    </div>
  </article>
</section>
