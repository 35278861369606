<section class="container-aboutus">
  <h1 class="main-title" data-aos="fade-down" data-aos-duration="2500">
    Sobre <strong id="principios-word">nosotros</strong>
  </h1>
  <div class="divider" data-aos="fade-left" data-aos-duration="2500"></div>

  <div class="aboutus-box">
    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon"></i>
        <span> Entradas VIP </span>
      </div>
      <p>
        Sorteamos entradas VIP para todos los partidos. ¡Solo para clientes!
      </p>
    </div>

    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon"></i>
        <span> Libertad </span>
      </div>
      <p>Con Celta Mobile eres libre, sin ataduras.* <br /></p>
      <p style="font-size: 12px">
        *(En tarifas de Fibra + móvil son 12 meses de permanencia)
      </p>
    </div>

    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon"></i>
        <span> Atención 24/7 </span>
      </div>
      <p>A cualquier hora, para cualquier duda.</p>
    </div>

    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon" svgIcon="grade"></i>
        <span> Nos adaptamos </span>
      </div>
      <p>
        Tú decides lo que necesitas. <br />
        No pagues por servicios que no utilizas.
      </p>
    </div>
  </div>

  <div class="slider-container"></div>
</section>
