<section>
  <div class="sorteos-image"></div>

  <div class="welcome">
    <h1 data-aos="fade-down" data-aos-duration="1500">
      <strong style="color: #6cace4">ENTRADAS VIP</strong>
      TODOS LOS MESES
    </h1>

    <div class="divider" data-aos="fade-right" data-aos-duration="1500"></div>
    <div class="container-img">
      <img src="" />
    </div>

    <h2>
      Sorteamos entradas en el anillo VIP entre nuestros clientes para el
      estadio Abanca Balaídos... <br />
      ¡Todos los meses!
    </h2>
  </div>
</section>
