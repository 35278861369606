import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { map, tap } from "rxjs/operators";
import { Pack } from "src/app/models/pack.interface";
import { CartService } from "src/app/services/cart.service";
import { SelectorService } from "src/app/services/selector.service";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.css"],
})
export class OffersComponent implements OnInit {
  packs: Pack[] = [];
  destacados : Pack[] = []
  showInfo = false

  constructor(
    private selectorService: SelectorService,
    private cartService: CartService,
    private _snackBar: MatSnackBar,
    private dialog:MatDialog
  ) {}

  ngOnInit(): void {
    /*   this.packs = this.selectorService.getAllPacks(); */
    this.getPacksDestacados();
  }
  //Function returns last value of an array that contains a name as a key
  returnObject(object: any, name: string) {
    let result: string | number | any | null = null;
    object.forEach((element) => {
      if (element.name === name) {
        if (name === "lineas") {
          result = parseInt(element?.options.at(-1));
        } else if (name === "velocidad" || name === "datosmoviles") {
          result = parseInt(element?.options.at(-1).slice(0, -2));
        } else {
          result = element?.options.at(-1);
        }
      }
    });
    return result;
  }

  returnType(object) {
    let result;
    if (object.slug === "Internet") {
      result = "fibra";
    } else if (object.slug === "movil") {
      result = "movil";
    } else if (object.slug === "pack") {
      result = "pack";
    } else {
      result = null;
    }
    return result;
  }

  getPacksDestacados() {
    this.selectorService
      .getPackAll("&per_page=100")
      .pipe(
        map((response: any) => {

          let array: any = [];
          for (const key in response) {
            if (
              response[key].tags[1]?.slug === "destacado" ||
              response[key].tags[0]?.slug === "destacado"
            ) {
  
              array.push({
                name: response[key].name,
                price: parseFloat(response[key].price),
                type: this.returnType(response[key].categories[0]),
                items: {
                  lines: this.returnObject(response[key].attributes, "lineas"),
                  landline: this.returnObject(response[key].attributes, "fijo"),
                  mobileData: this.returnObject(
                    response[key].attributes,
                    "datosmoviles"
                  ),
                  fiberSpeed: this.returnObject(
                    response[key].attributes,
                    "velocidad"
                  ),
                  extra: this.returnObject(response[key].attributes, "extra")
                    ? [this.returnObject(response[key].attributes, "extra")]
                    : [],
                },
              });
            }
          }
          return array;
        }),
        tap((packs: Pack[]) => {
          this.packs = packs;
        })
      )
      .subscribe();
  }

  getInteger(num: number) {
    return Math.floor(num);
  }
  getDecimal(num: number) {
    return num.toFixed(2).split(".")[1];
  }

  getTariffFromString(pack_name: string) {
    let selected_pack: Pack | undefined = this.packs.find(
      (item) => item.name == pack_name
    );
    if (selected_pack) {
      this.selectorService.addToCart(selected_pack);
      this._snackBar.open("¡" + selected_pack.name + " añadido!", "Cerrar", {
        duration: 2000,
      });
    }
  }
}
